import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'app-ref-data-form-fields',
  standalone: true,
  imports: [TableModule, ButtonModule, TooltipModule, InputTextModule,
    DialogModule, InputSwitchModule, DropdownModule, FormsModule, EditorModule,
    ReactiveFormsModule, CommonModule,  CheckboxModule, CalendarModule, MultiSelectModule],
  templateUrl: './ref-data-form-fields.component.html',
  styleUrl: './ref-data-form-fields.component.scss'
})
export class RefDataFormFieldsComponent {
  @Input() form: any;
  @Input() cols: any[];
  @Output() enableCustomDialogComponent = new EventEmitter<boolean>();

  enableCustomDialog() {
    this.enableCustomDialogComponent.emit(true);
  }

  isFieldRequired(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    if (!control) return false;
    const validator = control.validator ? control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  reorderItems(col: any) {
    const selectedItems = this.form.controls[col.field]?.value || [];
  
    // Create a map for quick lookup of selected items by ID
    const selectedMap = new Map<number, boolean>();
    selectedItems.forEach((id: number) => selectedMap.set(id, true));
  
    // Reorder the dropdown content
    col.sortedDropdownContent = col.dropdownContent.sort((a: any, b: any) => {
      const aSelected = selectedMap.has(a.id);
      const bSelected = selectedMap.has(b.id);
  
      if (aSelected && !bSelected) {
        return -1; // a is selected, b is not, a comes first
      } else if (!aSelected && bSelected) {
        return 1;  // b is selected, a is not, b comes first
      } else {
        return 0;  // both selected or both not selected, maintain original order
      }
    });
  }

}
