export const environment = {
    name: 'dev',
    useFailOverCognito: false,
    userPoolId: 'eu-central-1_KNAiHuhxs',
    userPoolWebClientId: '4t1sn8ma4rvio12ddls235p5dp',
    cognitoDomain: 'momdev.auth.eu-central-1.amazoncognito.com',
    failoverUserPoolId: 'us-east-1_bOPsNoDMn',
    failoverUserPoolWebClientId: 'k55gch9ohgik2ctb2va8k40ut',
    failoverCognitoDomain: 'momfailoverdev.auth.us-east-1.amazoncognito.com',
    redirectURL: window.location.origin + '/login',
    primaryApiUrl: 'https://api.dev.mom.kochmetals.com',
    secondaryApiUrl: 'https://api.failover.dev.mom.kochmetals.com',
    primaryWebsocketUrl: 'wss://websocket.dev.mom.kochmetals.com',
    secondaryWebsocketUrl: 'wss://websocket.failover.dev.mom.kochmetals.com',
    primaryOrderApiLoadBalancerUrl: 'https://api.dev.order.mom.kochmetals.com',
    secondaryOrderApiLoadBalancerUrl: 'https://api.failover.dev.order.mom.kochmetals.com',
    //primaryOrderApiLoadBalancerUrl: 'https://localhost:7025',
    //secondaryOrderApiLoadBalancerUrl: 'https://localhost:7025',
    githubCommitSha: '780ad14',
    githubDeploymentDate: '2025-03-24T12:41:21Z'
};
