<form [formGroup]="form" class="form-content grid pl-4 pr-4 pt-3">
    <ng-container *ngFor="let col of cols">
        <div *ngIf="col.inputType === 'text' && col.header != 'Updated By'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}} 
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <input class="ml-2" pInputText [id]="col.field" type="text" [formControlName]="col.field">
        </div>

        <div *ngIf="col.inputType === 'checkbox'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-checkbox class="ml-1" [formControlName]="col.field" [binary]="true" inputId="displayButton" />
        </div>

        <div *ngIf="col.inputType === 'slider'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-inputSwitch [formControlName]="col.field"></p-inputSwitch>
        </div>

        <div *ngIf="col.inputType === 'dropdown' || col.inputType === 'hybriddropdown'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-dropdown class="ml-2" appendTo="body" filter="true" [formControlName]="col.field"
                [options]="col.dropdownContent" [optionLabel]="col.entityPropertyName" [optionValue]="col.entityPropertyName" placeholder="Select a {{col.header}}"
                [virtualScroll]="true" [virtualScrollItemSize]="38" [resetFilterOnHide]="true" [showClear]="true" />
            <button *ngIf="col.displayCustomDialogButton" pButton class="ml-2" icon="pi pi-plus" pTooltip="Add Region" tooltipPosition="bottom"
                (click)="enableCustomDialog()"></button>
        </div>

        <div *ngIf="col.inputType === 'date'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-calendar
            type="date"
            appendTo="body"
            [showIcon]="true" 
            [showOnFocus]="false" 
            inputId="buttondisplay" 
            [formControlName]="col.field"/>
        </div>

        <div *ngIf="col.inputType === 'multiselect' || col.inputType === 'hybridmultiselect'"
            class="form-group flex align-items-center col-12 lg:col-6 md:col-6 sm:col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-multiSelect id="multiSelect"
            [options]="col.dropdownContent" 
            [formControlName]="col.field"
            appendTo="body" 
            [optionLabel]="col.entityPropertyName" [optionValue]="col.multiselectValue"
            [filter]="true" 
            (onPanelShow)="reorderItems(col)"
            [virtualScroll]="true" [virtualScrollItemSize]="38" [resetFilterOnHide]="true" 
            placeholder="Select {{col.header}}"/>
        </div>

        <div *ngIf="col.inputType === 'texteditor'"
            class="form-group flex align-items-center col-12 pt-3 pb-3">
            <label class="font-semibold" [for]="col.field">{{col.header}}
                <span *ngIf="isFieldRequired(col.field)" class="text-red-500">*</span>
            </label>
            <p-editor [formControlName]="col.field" [style]="{ height: '320px' }" />
        </div>
    </ng-container>
</form>