<p-confirmPopup />
<p-table #dt1 [columns]="cols" [value]="tableData" [loading]="loading" selectionMode="single"
    [resizableColumns]="isColumnResizable"
    styleClass="p-datatable-gridlines p-datatable-sm p-datatable p-datatable-striped custom-table" class="p-table"
    #pTable scrollHeight="65vh" [virtualScroll]="true" [virtualScrollItemSize]="46"
    [style]="{ height: 'flex', 'max-height': '70vh'}" [globalFilterFields]="globalFilters" sortMode="multiple">
    <ng-template pTemplate="caption" *ngIf="createNewRecordLabel">
        <div class="flex">
            <button pButton icon="pi pi-plus" [label]="createNewRecordLabel"
                class="p-button-success p-button-sm p-button-raised" (click)="enableNewDialog()">
            </button>
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                    placeholder="Search" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ngClass]="{'icons-column-width':col.inputType === 'actionButtons'}"
                [pSortableColumn]="getSortableField(col)">
                {{ col.header }}
                <!-- This will show the notes icon if the notes field is filled out on the column and sort icon for these input types -->
                <ng-container>
                    <i class="pi" style="cursor:pointer" [ngClass]="{'pi-info-circle': col.notes}"
                        pTooltip="{{ col.notes }}">
                    </i>
                    <p-sortIcon [field]="getSortableField(col)" />
                </ng-container>
                <ng-container *ngIf="col.inputType === 'checkbox' || col.inputType ==='slider'">
                    <p-columnFilter type="boolean" field="{{col.field}}" placeholder="Search by {{ col.header }}"
                        display="menu" />
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <span *ngIf="col.inputType === 'text' || col.inputType === 'hybriddropdown'">
                    {{ rowData[col.field] }}
                </span>

                <span *ngIf="col.inputType === 'hybridmultiselect'">
                    <ng-container *ngFor="let item of rowData[col.field]; let i = index">
                        {{ item[col.entityPropertyName] }}<span *ngIf="i < rowData[col.field].length - 1">, </span>
                    </ng-container>
                </span>


                <span *ngIf="col.inputType === 'texteditor'">
                    <div [innerHTML]="getTruncatedText(rowData[col.field], rowData.id)"></div>
                    <a *ngIf="rowData[col.field]?.length > 200">
                        {{ expandedRows[rowData.id] ? '[less]' : '[...edit to see all text]' }}
                    </a>
                </span>

                <span *ngIf="col.inputType === 'date'">
                    {{ rowData[col.field] | date:
                    (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ??
                    'yyyy/MM/dd') }}
                </span>

                <span *ngIf="col.inputType === 'dateTime'">
                    {{ rowData[col.field] | date:
                    (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ??
                    'yyyy/MM/dd') + ' H:mm a' }}
                </span>

                <span *ngIf="col.inputType === 'checkbox'">
                    <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true" [disabled]="true" />
                </span>

                <span *ngIf="col.inputType === 'slider'">
                    <p-inputSwitch [(ngModel)]="rowData[col.field]" (onChange)="activateDeactivateRefData(rowData)"
                        [disabled]="true"></p-inputSwitch>
                </span>

                <span *ngIf="col.inputType === 'dropdown'">
                    <span *ngIf="col.displayType !== 'text'; else useTextType">
                        <span *ngIf="rowData[col.field]?.active; else strikethrough">
                            {{ rowData[col.field]?.[col.entityPropertyName] || '' }}
                        </span>
                        <ng-template #strikethrough>
                            <s style="color:pink">{{ rowData[col.field]?.[col.entityPropertyName] || '' }}</s>
                        </ng-template>
                    </span>
                    <ng-template #useTextType>
                        {{ rowData[col.field] }}
                    </ng-template>
                </span>

                <span *ngIf="col.inputType === 'actionButtons'">
                    <ng-container *ngFor="let icon of col.icons">
                        <button *ngIf="icon === 'pi-pencil'" tooltipPosition="top" pButton icon="pi pi-pencil"
                            class="p-button-secondary p-button-sm p-button-raised hoverable-warning" pTooltip="Edit"
                            (click)="enableEditAndDuplicateDialog(rowData, false)"></button>
                        <button *ngIf="icon === 'pi-copy'" tooltipPosition="top" pButton icon="pi pi-copy"
                            class="p-button-secondary p-button-sm p-button-raised hoverable-help ml-1" pTooltip="Create Copy"
                            (click)="enableEditAndDuplicateDialog(rowData, true)"></button>
                    </ng-container>
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>